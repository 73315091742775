/* infiniteLoadingComponent */
.infinite-container {
  padding: 8px 16px;
  min-height: 320px;
  /* border: 1px solid #e8e8e8; */
  border-radius: 4px;
  overflow: auto;
  height: 640px;
}

.infinite-container-small {
  overflow: auto;
  padding: 8px 8px;
  min-height: 320px;
}

.loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
